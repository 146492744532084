<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Forgot Password</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href=" https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <div class="form-group last mb-3">
                    <label for="email">Email</label>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="your-email@gmail.com" id="">

                </div>

                <input v-on:click="Submit" type="submit" value="Submit" class="btn btn-common">
               


            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>

</template>

<script>




import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
export default {
        name: 'ForgotPassword',
        data() {
            return {

                email: ''
            }
        },
        methods: {
            async Submit() {
                axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                const data = {
                    email: this.email,
                    

                }

                let result = await axios.post("/app/forget_password_app/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9/9150", data);
                if (result.data.status == true) {
                    sessionStorage.setItem("email", this.email);
                    sessionStorage.setItem("ForgotPassword", "1");
                    this.$router.push('/OTPVerify');
                } else if (result.data.status == false) {
                    sessionStorage.setItem("email", this.email);
                    this.$router.push('/OTPVerify');
                }

            }
        }
    }
</script>