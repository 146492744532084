<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">OTP Verification</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>

    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <div class="bg-white col-md-8 m-auto card bg-white p-3 py-5">
                    <div class="d-flex">
                        <div class="form-group mx-2">
                            <input type="text" class="form-control cust-control" placeholder="-" id="" v-model="one">
                        </div>

                        <div class="form-group mx-2">
                            <input type="text" class="form-control cust-control" placeholder="-" id="" v-model="two">
                        </div>

                        <div class="form-group mx-2">
                            <input type="text" class="form-control cust-control" placeholder="-" id="" v-model="three">
                        </div>

                        <div class="form-group mx-2">
                            <input type="text" class="form-control cust-control" placeholder="-" id="" v-model="four">
                        </div>
                    </div>
                    <br />
                    <h5><span class="text-common" id="time">00</span> Seconds Remaining</h5>
                    <input type="submit" value="Verify" class="btn btn-common w-25" v-on:click="Submit">
                    <p class="mb-4" id="Resend">Don't recieve the OTP? <a href="#" class="text-common"  v-on:click="Resend" >Resend</a></p>

                    <p class="text-common font-weight-bold">Find your OTP here</p>
                    <div class="card p-1 shadow-sm bg-white mb-1">
                        <div class="d-flex">
                            <img src="https://static-00.iconduck.com/assets.00/gmail-icon-1024x1024-09wrt8am.png">
                            <p class="my-auto text-dark pl-2 font-weight-bold" id="email"></p>
                        </div>
                    </div>
                    <div class="card p-1 shadow-sm bg-white mb-1">
                        <div class="d-flex">
                            <img src="https://freepngimg.com/download/google/66857-google-icons-sms-computer-messenger-facebook-android.png">
                            <p class="my-auto text-dark pl-2 font-weight-bold">00000 00000</p>
                        </div>
                    </div>
                    <div class="card p-1 shadow-sm bg-white mb-1">
                        <div class="d-flex">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png">
                            <p class="my-auto text-dark pl-2 font-weight-bold">00000 00000</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>
</template>


<script>
   import axios from "axios";
   axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
   import $ from 'jquery';
   

   
   


  
   export default {
                name: 'OTPVerify', 
            data(){
                 return {
                    one: '',
                     two: '',
                     three: '',
                     four: ''
                    }
             },
             mounted: function () {
                            var fiveMinutes = 60 * 5,
                            display = document.querySelector('#time');
                            $('#Resend').hide();
                             $('#email').html(sessionStorage.getItem("email"));
                            this.startTimer(fiveMinutes, display)

             },
             methods:{
                 async Submit() {
                 
                    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                    const data = {
                        otp: this.one+this.two+this.three+this.four,
                        email:sessionStorage.getItem("email")
                        
                    }
              
                    let result = await axios.post("app/verify_otp/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9/9150", data);
                    if(result.data.status == true){
                        console.error(sessionStorage.getItem("ForgotPassword"));
                        if(sessionStorage.getItem("ForgotPassword") ==  "1"){
                            sessionStorage.setItem("email", sessionStorage.getItem("email"))
                            this.$router.push('/NewPassword');
                        }else{
                            this.$router.push('/RegBus');

                        }
                        

                    }
                 },
                 async Resend() {

                   axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                   //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                   const data = {
                       email:sessionStorage.getItem("email")
       
                   }
                  
                   let result = await axios.post("app/forget_password_app/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9/9150", data);
                   if(result.data.status == true){
                        //alert("NEW OTP SENT TO YOUR EMAIL")
                        this.$router.push('/OTPVerify');
                   }

                },
                startTimer(duration, display) {
                        var timer = duration, minutes, seconds;
                        setInterval(function () {
                            minutes = parseInt(timer / 60, 10);
                            seconds = parseInt(timer % 60, 10);

                            minutes = minutes < 10 ? "0" + minutes : minutes;
                            seconds = seconds < 10 ? "0" + seconds : seconds;
                            if(minutes == 0 && seconds == 0 ){
                                 $('#Resend').show();
                            }
                            display.textContent = minutes + ":" + seconds;

                            if (--timer < 0) {
                                timer = duration;
                            }
                        }, 1000);
                }


             }

             }

             

   </script>
