<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">LogIn</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <div class="form-group last mb-3">
                    <label for="email">Email</label>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="your-email@gmail.com" id="">

                </div>
                <div class="form-group last mb-3">
                    <label for="password">Password</label>
                    <input name="password" type="password" class="form-control" v-model="password" placeholder="********" id="">

                </div>

                <input v-on:click="Submit" type="submit" value="Submit" class="btn btn-common">
                <input v-on:click="Register" type="submit" value="Register Now" class="btn btn-common  ml-1" />
                 <a href="#" class="text-common ml-2" v-on:click="Forgot" >Forget Password</a>


            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>

</template>

<script>
   



import axios from "axios";
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
export default {
        name: 'LogIn',
        data() {
            return {
                
                email: '',
                password: ''
            }
        },
        methods: {
            async Submit() {
                axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                const data = {
                    email: this.email,
                    password: this.password
                    
                }

                let result = await axios.post("/app/login/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", data);
                
                if (result.data.status == true) {
                    sessionStorage.setItem("email", this.email);
                    sessionStorage.setItem("login_details", JSON.stringify(result.data));
                    const para = {
                        user_id: result.data.data //8877  
                    }
                    let buss = await axios.post("/app/registration_detail/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", para);
                    if(buss.data.status == true){
                        sessionStorage.setItem("login_details",JSON.stringify(buss.data));
                        this.$router.push('/RegSuccess');
                    }else if(buss.data.status == false){
                        this.$router.push('/RegBus');
                    }
                    
                } else if (result.data.status == false) {
                    //sessionStorage.setItem("email", this.email);
                    this.$router.push('/LogIn');
                }

            },
            Register() {
                this.$router.push('/QuickReg');
            },
            Forgot() {
                this.$router.push('/Forgot');
            }
        }
    }
</script>