import QuickReg from './components/QuickReg.vue'
import RegBus from './components/RegBus.vue'
import UpdateBuss from './components/UpdateBuss.vue'
import RegSuccess from './components/RegSuccess.vue'
import OTPVerify from './components/OTPVerify.vue'
import LogIn from './components/Login.vue'
import ForgotPassword from './components/Forgot.vue'
import NewPassword from './components/NewPassword.vue'
import { createRouter, createWebHistory } from 'vue-router'


const routes = [

    {
       name: 'LogIn',
       component: LogIn,
        path: '/LogIn',
    },
    {
       name: 'QuickReg',
       component: QuickReg,
       path:'/',
},
{
    name: 'RegBus',
    component: RegBus,
     path: '/RegBus',

    },
    {
        name: 'UpdateBuss',
        component: UpdateBuss,
        path: '/UpdateBuss',
    },
    {
        name: 'RegSuccess',
        component: RegSuccess,
        path: '/RegSuccess',
    },
    {
        name: 'OTPVerify',
        component: OTPVerify,
        path: '/OTPVerify',
    },
    {
        name: 'ForgotPassword',
        component: ForgotPassword,
        path: '/Forgot',
    },
    {
        name: "NewPassword",
        component:NewPassword,
        path:"/NewPassword"
    }
    



];

const router = createRouter({
    history: createWebHistory(process.env.path),
	alias: '*',
    routes,
})

export default router;
