<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Business Registration</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>

    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <form action="#" method="post" class="">
                    <div class="form-group first">
                        <label for="name">Business Name</label>
                        <input type="text" class="form-control" placeholder="Business Name" v-model="name" id="">
                    </div>
                    <div class="form-group first">
                        <label for="name">Email</label>
                        <input type="text" class="form-control" placeholder="abc@gmail.com" v-model="email" id="">
                    </div>
                    <div class="form-group first">
                        <label for="name">Mobile</label>
                        <input type="text" class="form-control" placeholder="0000 0000000" v-model="mobile" id="">
                    </div>
                    <div class="form-group last mb-3">
                        <label for="file">Biz Card/ Invoice/ Signboard image <span class="text-common">(Any One)</span></label>
                        <div class="input-group">
                            <input type="file" ref="businessCard" id="businessCard" class="form-control" placeholder="Upload File" aria-label="Upload File"  v-on:change="UploadFile()" aria-describedby="basic-addon2" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()" v-on:change="UploadFile()"><i class="fa fa-upload"></i></button>
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()" v-on:change="UploadFile()"><i class="fa fa-camera"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group last mb-3">
                        <label for="image">Image of your sales invoice /Form20 /Form 21 <span class="text-common">(Any One)</span></label>
                        <div class="input-group">
                            <input type="file" ref="shopImage" id="shopImage"   v-on:change="UploadImage()" class="form-control" placeholder="Upload Image" aria-label="Upload Image"  aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()" v-on:change="UploadImage()"><i class="fa fa-upload"></i></button>
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()" v-on:change="UploadImage()"><i class="fa fa-camera"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group last mb-3">
                        <label for="address">Address</label>
                        <textarea v-model="address" rows="4" class="form-control" placeholder="abc" id=""></textarea>
                    </div>
					<div class="form-group first">
                        <label for="name">PinCode</label>
                        <input type="text" class="form-control" placeholder="PinCode" v-model="pincode" id="pincode">
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="state">Business Timings 24 Hours</label>
                                <select v-model="business_timings_24_hours" class="form-control" >
                                    <option value="true">True</option>
                                    <option value="false" selected="selected">False</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="district">Business 7 Days</label>
                                <select v-model="business_7_days" class="form-control" >
                                    <option value="true" selected="selected">True</option>
                                    <option value="false" >False</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="city">selected Time Start</label>
                                <input type="time" class="form-control" placeholder="" v-model="selectedTimeStart" >
                                    
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="pincode">Selected Time End</label>
                                <input v-model="selectedTimeEnd" type="time" class="form-control" placeholder="" id="">
                            </div>
                        </div>
                    </div>

                    <input v-on:click="Submit" value="Submit" class="btn btn-common w-25">
                </form>
            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    export default {
        name: 'RegBus',
        data() {
            return {
                name: '',
                email:'',
                mobile:'',
                business_type_id:'19',
                businessCard: '',
                shopImage: '',
                business_timings_24_hours:false,
                business_7_days:true,
                selectedDayOff:'',
                selectedTimeStart:'',
                selectedTimeEnd:''
                /*address: '',
                state: '',
                district: '',
                city: '',
                pincode: '',
                email: '',
                mobile:'',*/
            }
        },
        methods: {
            Check(){
                alert(this.shopImage);
                let formData = new FormData();
                    formData.append('businessCard', this.businessCard);
                    formData.append('shopImage', this.shopImage);
                    console.error(formData);
            },
            async Submit() {
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('email', this.email);
                formData.append('mobile', this.mobile);
				formData.append('address', this.address);
                    formData.append('businessCard', this.businessCard);
                    formData.append('shopImage', this.shopImage);
                    formData.append('business_timings_24_hours', this.business_timings_24_hours);
                    formData.append('business_7_days', this.business_7_days);
                    formData.append('selectedDayOff', this.selectedDayOff);
                    formData.append('selectedTimeStart', this.selectedTimeStart);
                    formData.append('selectedTimeEnd', this.selectedTimeEnd);
                    formData.append('business_type_id', this.business_type_id);
					formData.append('source_flag',1);
					formData.append('pincode',this.pincode);
                    
               
                    
                axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                let result = await axios.post("/app/register_business/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", formData);
                if(result.data.status == true){
                    const para = {
                        user_id: result.data.data  
                    }
                    let buss = await axios.post("/app/registration_detail/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", para);
                    if(buss.data.status == true){
                        sessionStorage.setItem("login_details",JSON.stringify(buss.data));
                        this.$router.push('/LogIn');
                    }else {
                        this.$router.push('/LogIn');
                    }
                }
            },
            UploadFile(){
                
                this.businessCard = this.$refs.businessCard.files[0];
                
                
            },
            UploadImage(){
                
                this.shopImage = this.$refs.shopImage.files[0];
                
            }
        }
    }
</script>

