<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Update Business Registration</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>

    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <form action="#" method="post" class="">
                    <div class="form-group first">
                        <label for="name">Business Name</label>
                        <input type="text" class="form-control" placeholder="Business Name" v-model="BN" id="">
                    </div>
                    <div class="form-group first">
                        <label for="name">Email</label>
                        <input type="text" class="form-control" placeholder="abc@gmail.com" v-model="email" id="">
                    </div>
                    <div class="form-group first">
                        <label for="name">Mobile</label>
                        <input type="text" class="form-control" placeholder="0000 0000000" v-model="mobile" id="">
                    </div>
                    <div class="form-group last mb-3">
                        <label for="file">Biz Card/ Invoice/ Signboard image <span class="text-common">(Any One)</span></label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Upload File" aria-label="Upload File" v-model="file" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()"><i class="fa fa-upload"></i></button>
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()"><i class="fa fa-camera"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group last mb-3">
                        <label for="image">Image of your sales invoice /Form20 /Form 21 <span class="text-common">(Any One)</span></label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Upload Image" aria-label="Upload Image" v-model="image" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()"><i class="fa fa-upload"></i></button>
                                <button class="btn btn-outline-secondary" type="button" onclick="importDatafile()"><i class="fa fa-camera"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group last mb-3">
                        <label for="address">Address</label>
                        <textarea v-model="address" rows="4" class="form-control" placeholder="abc" id=""></textarea>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="state">Select State</label>
                                <select v-model="state" class="form-control" id="">
                                    <option>1</option>
                                    <option>1</option>
                                    <option>1</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="district">Select District</label>
                                <select v-model="district" class="form-control" id="">
                                    <option>1</option>
                                    <option>1</option>
                                    <option>1</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="city">Select City</label>
                                <select v-model="city" class="form-control" id="">
                                    <option>1</option>
                                    <option>1</option>
                                    <option>1</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group last mb-3">
                                <label for="pincode">Enter Pincode</label>
                                <input v-model="pincode" type="text" class="form-control" placeholder="000000" id="password">
                            </div>
                        </div>
                    </div>

                    <input v-on:click="Submit" type="submit" value="Submit" class="btn btn-common w-25">
                </form>
            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>
</template>
<script>import axios from "axios";
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    export default {
        name: 'UpdateBuss',
        data() {
            return {
                BN: '',
                file: '',
                image: '',
                address: '',
                state: '',
                district: '',
                city: '',
                pincode: '',
                email: '',
                mobile:'',
            }
        },
        methods: {
            async Submit() {
                axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
                //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                let result = await axios.post("https://his.kizakumd.com/app/register_business/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", {
                    name: this.BN,
                    email: this.email,
                    mobile: this.mobile,
                    businessCard: this.file,
                    shopImage: this.image,
                    business_timings_24_hours: false,
                    business_7_days:true,
                    selectedDayOff: '',
                    selectedTimeStart: '',
                    selectedTimeEnd:'',
                    business_type_id: '19',
                    "Access-Control-Allow-Origin": '*'
                });
                console.warn(result);
            }
        }
    }</script>

